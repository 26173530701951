<template>
  <div class="container-conteudo">
    <div class="conteudo-interno">
      <div class="nova-comunicacao-container">
        <h2>Editar Autorizada</h2>
        <transition name="fade">
          <FormularioAutorizadas :editar="true" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import FormularioAutorizadas from "@/components/elementos/paginas/autorizadas/FormularioAutorizadas.vue";

export default {
  name: "AutorizadasEditar",
  components: {
    FormularioAutorizadas
  }
}
</script>

<style>
</style>
